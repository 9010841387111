
import Vue, { PropType } from 'vue';
import { MultipollAnswer, MultipollChoice, MultipollChoiceEnum, MultipollChoiceInput as MultipollChoiceInputType } from '@/api/multipoll';
import MultipollChoiceInput from '@/components/Multipoll/Submit/MultipollChoiceInput.vue';
import MultipollAnotherPerson from '@/components/Multipoll/Submit/MultipollAnotherPerson.vue';
import moment from 'moment';

interface State {
  input: MultipollChoiceEnum | null,
}

export default Vue.extend({
  name: 'MultipollChoiceCard',
  components: { MultipollAnotherPerson, MultipollChoiceInput },
  props: {
    multipollChoice: {
      type: Object as PropType<MultipollChoice>,
      required: true,
    },
    multipollChoiceInput: {
      type: Object as PropType<MultipollChoiceInputType>,
    },
    otherAnswers: {
      type: Array as PropType<MultipollAnswer[]>,
      required: true,
    },
  },
  data(): State {
    return {
      input: this.multipollChoiceInput?.choice,
    };
  },
  computed: {
    formattedDateChoice(): string {
      return moment(this.multipollChoice.date).format('Do MMMM, dddd');
    },
  },
  watch: {
    input(newValue) {
      this.$emit('input', {
        date: this.multipollChoice.date,
        choice: newValue,
      });
    },
  },
});
